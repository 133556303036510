// @TODO: REVIEW VOYAGER BEHAVIOUR
const removeDuplicateRefs = (acc, curr) => {
  const accRefs = acc.map(({ ref }) => ref);
  if (!accRefs.includes(curr.ref)) {
    acc.push(curr);
  }
  return acc;
};

const applyStrategy = (values = [], newValue = {}) => {
  if (newValue.constructor === Object && Object.keys(newValue).length > 0) {
    values.push(newValue);
  }
  const sanitizeValues = values.reduce(removeDuplicateRefs, []);
  const { 0: first = {}, length: l, [l - 1]: last } = sanitizeValues;

  if (first.constructor !== Object || Object.keys(first).length === 0) {
    return [];
  }
  if (last.ref === first.ref) {
    return [first];
  }
  return [first, last];
};

export { applyStrategy };
