import { StorageManager } from './bridges';
import { emitUserEvent } from './helpers';

const EVENT_DEFAULTS = { event: 'pageview', eventCategory: 'navigation' };

const STORE_ID = {
  ES: 1,
  FR: 2,
  UK: 3,
  PT: 4,
  BE: 5,
  IT: 6,
  DE: 7,
  AT: 8,
  NL: 9,
  SE: 10,
  CH: 11,
};

const CLIENT_STORAGE_KEY = 'ltmdata';

const userTracker = ({ section, store, userLogged = false, ...rest } = {}) => {
  if (section) {
    const eventData = {
      ...EVENT_DEFAULTS,
      section,
      store: store ? STORE_ID[store] : 0,
      userLogged,
      // This will allow to personalize the tracking depending on the user data
      ...rest,
    };

    emitUserEvent(eventData);
    StorageManager.set(CLIENT_STORAGE_KEY, eventData);
  }
};

export { userTracker, CLIENT_STORAGE_KEY };
