const SEARCHER = 'searcher';
const SOCIAL = 'social';
const ATTRIBUTORS = [
  { name: 'google', type: SEARCHER, adParam: 'gclid=' },
  { name: 'bing', type: SEARCHER, adParam: 'gclid=' },
  { name: 'linkedin', type: SOCIAL, adParam: '' },
  { name: 'facebook', type: SOCIAL, adParam: 'ad_id=' },
  { name: 'pinterest', type: SOCIAL, adParam: '' },
  { name: 'twitter', type: SOCIAL, adParam: '' },
  { name: 'instagram', type: SOCIAL, adParam: '' },
  { name: 'yahoo', type: SEARCHER, adParam: '' },
  { name: 'yandex', type: SEARCHER, adParam: '' },
  { name: 'duckduckgo', type: SEARCHER, adParam: '' },
  { name: 'ask.fm', type: SEARCHER, adParam: '' },
  { name: 'ask', type: SEARCHER, adParam: '' },
  { name: 'ecosia', type: SEARCHER, adParam: '' },
  { name: 'baidu', type: SEARCHER, adParam: '' },
];

export const getAttributor = (url = '') => {
  let attributor = { s: '', ad: false };

  const found = ATTRIBUTORS.find(item => url.indexOf(item.name) !== -1);
  if (found) {
    attributor = {
      s: found.type,
      ad: !!found.adParam && url.indexOf(found.adParam) !== -1,
    };
  }

  return attributor;
};
