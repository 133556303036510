export const StorageManager = {
  get: key => {
    const storage = sessionStorage.getItem(key);
    return storage ? JSON.parse(storage) : undefined;
  },

  set: (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.warn('Error saving event', error, value);
    }
  },

  remove: key => sessionStorage.removeItem(key),
};
