import { CookieStorage } from './CookieStorage';
import { getTopLevelDomain, applyStrategy } from '../helpers';

const TrackerManager = {
  get: key => {
    try {
      const values = CookieStorage.get(key, { domain: `.${getTopLevelDomain(window.location.hostname)}` });
      return values ? JSON.parse(values) : [];
    } catch (error) {
      // @TODO: Sentry logs
      TrackerManager.remove(key);
      return [];
    }
  },
  set: (key, data, expires = 365, path = '/') => {
    const values = TrackerManager.get(key);
    const newValues = applyStrategy(values, data);
    CookieStorage.set(key, JSON.stringify(newValues), {
      expires,
      path,
      domain: `.${getTopLevelDomain(window.location.hostname)}`,
    });
  },
  remove: key =>
    CookieStorage.remove(key, {
      domain: `.${getTopLevelDomain(window.location.hostname)}`,
    }),
};

export { TrackerManager };
