import { TrackerManager } from './bridges';
import { getAttributor, getDevice, getTimestamp, getTld, sanitizeReferrer, url } from './helpers';

const UTM_SOURCE = 'utm_source';
const UTM_KEYS = ['utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const CAMPAIGN_TRACKER_COOKIE = 'voyager';

const campaignTracker = (cookieName = CAMPAIGN_TRACKER_COOKIE) => {
  const utmSource = url.get(UTM_SOURCE);
  if (!utmSource) return;

  const { referrer = '' } = document;
  const cookie = {
    utm_source: utmSource,
    ref: sanitizeReferrer(referrer),
    tld: getTld(referrer),
    ua: getDevice(),
    ts: getTimestamp(),
    ...getAttributor(referrer),
  };
  UTM_KEYS.filter(key => url.has(key)).forEach(key => (cookie[key] = url.get(key)));

  TrackerManager.set(cookieName, cookie);
};

export { CAMPAIGN_TRACKER_COOKIE, campaignTracker };
