import { ATTRIBUTION_COOKIE } from './attributionTracker';
import { TrackerManager } from './bridges';
import { CAMPAIGN_TRACKER_COOKIE } from './campaignTracker';

const sendTracker = async ({ authorization = '' }, { cookieName = '', type = '' }) => {
  const model = TrackerManager.get(cookieName);

  if (model && model.length) {
    const response = await fetch(`/api/${type}`, {
      method: 'POST',
      body: JSON.stringify({
        model: model,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
        Authorization: authorization,
      },
    });
    if (response.ok) {
      TrackerManager.remove(cookieName);
    }
  }
};

const sendTrackerModel = ({ authorization = '' }) => {
  const sendTrackerByHeaders = sendTracker.bind(undefined, { authorization });
  const sendAttributionModel = () =>
    sendTrackerByHeaders({ cookieName: CAMPAIGN_TRACKER_COOKIE, type: CAMPAIGN_TRACKER_COOKIE });
  const sendCampaignModel = () => sendTrackerByHeaders({ cookieName: ATTRIBUTION_COOKIE, type: ATTRIBUTION_COOKIE });

  sendAttributionModel();
  sendCampaignModel();
};

export { sendTrackerModel };
