import { TrackerManager } from './bridges';
import { getAttributor, getDevice, getTimestamp, getTld, hasExternalReferrer, sanitizeReferrer, url } from './helpers';

const ATTRIBUTION_COOKIE = 'pathfinder';

const attributionTracker = () => {
  const hasUtmSource = url.has('utm_source');
  const { referrer = '' } = document;

  if (hasUtmSource || !hasExternalReferrer(referrer)) return;
  TrackerManager.set(ATTRIBUTION_COOKIE, {
    ref: sanitizeReferrer(referrer),
    tld: getTld(referrer),
    ua: getDevice(),
    ts: getTimestamp(),
    ...getAttributor(referrer),
  });
};

export { ATTRIBUTION_COOKIE, attributionTracker };
